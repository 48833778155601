/* Tomorrow Night Blue Theme */
/* http://jmblog.github.com/color-themes-for-google-code-highlightjs */
/* Original theme - https://github.com/chriskempson/tomorrow-theme */
/* http://jmblog.github.com/color-themes-for-google-code-highlightjs */


$title: #7285b7;
$pseudo: #ff9da4;
$params: #4a7aff;
$attributs: #ffeead;
$cdata: #d1f1a9;
$hex: #99ffff;
$function: #bbdaff;
$jsfunction: #ebbbff;
$substr: #d1f1a9;
$comment: green;
$number: #7285b7;
$keyword: rgb(79, 140, 255);
$function: rgb(116, 116, 255);
$builder : rgb(123, 202, 255);
$string: #ffa652;

code.hljs {
    color: $substr !important;
}

.hljs-params {
    color: $params;
}

.hljs-function {
    color: $function;
}

.hljs-built_in {
    color: $builder !important;
}



.tomorrow-comment,
pre .comment,
pre .title {
    color: $title !important;
}

.tomorrow-red,
pre .variable,
pre .attribute,
pre .tag,
pre .regexp,
pre .ruby .constant,
pre .xml .tag .title,
pre .xml .pi,
pre .xml .doctype,
pre .html .doctype,
pre .css .id,
pre .css .class,
pre .css .pseudo {
    color: $pseudo !important;
}

.tomorrow-orange,
pre .number,
pre .preprocessor,
pre .built_in,
pre .literal,
pre .params,
pre .constant {
    color: $params !important;
}

.tomorrow-yellow,
pre .class,
pre .ruby .class .title,
pre .css .rules .attribute {
    color: $attributs !important;
}

.tomorrow-green,
pre .string,
pre .value,
pre .inheritance,
pre .header,
pre .ruby .symbol,
pre .xml .cdata {
    color: $cdata !important;
}

.tomorrow-aqua,
pre .css .hexcolor {
    color: $hex !important;
}

.tomorrow-blue,
pre .function,
pre .python .decorator,
pre .python .title,
pre .ruby .function .title,
pre .ruby .title .keyword,
pre .perl .sub,
pre .javascript .title,
pre .coffeescript .title {
    color: $function !important;
}

.tomorrow-purple,
pre .keyword,
pre .javascript .function {
    color: $jsfunction !important;
}

pre code {
    display: block !important;
    background: #002451 !important;
    color: white;
    font-family: Menlo, Monaco, Consolas, monospace !important;
    line-height: 1.5 !important;
    border: 1px solid #ccc !important;
    padding: 10px !important;
}



/* .hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    background: #F0F0F0;
} */


/* Base color: saturation 0; */

.hljs,
.hljs-subst {
    color: $substr;
}

.hljs-comment {
    color: $comment;
}

.hljs-keyword,
.hljs-attribute,
.hljs-selector-tag,
.hljs-meta-keyword,
.hljs-doctag,
.hljs-name {
    color: $keyword;
    font-weight: bold;
}


/* User color: hue: 0 */

.hljs-string {
    color: $string;
}

.hljs-type,
.hljs-number,
.hljs-selector-id,
.hljs-selector-class,
.hljs-quote,
.hljs-template-tag,
.hljs-deletion {
    color: $number;
}

.hljs-title,
.hljs-section {
    color: $title;
    font-weight: bold;
}

.hljs-regexp,
.hljs-symbol,
.hljs-variable,
.hljs-template-variable,
.hljs-link,
.hljs-selector-attr,
.hljs-selector-pseudo {
    color: #BC6060;
}


/* Language color: hue: 90; */

.hljs-literal {
    color: #78A960;
}

.hljs-built_in,
.hljs-bullet,
.hljs-code,
.hljs-addition {
    color: #bbeb8b;
}


/* Meta color: hue: 200 */

.hljs-meta {
    color: #1f7199;
}

.hljs-meta-string {
    color: #4d99bf;
}


/* Misc effects */

.hljs-emphasis {
    font-style: normal;
}

.hljs-strong {
    font-weight: bold;
}